.App {
  margin: 0 auto;
  padding: 0;
  scroll-behavior: smooth;
  background: #fff;
  overflow: hidden;
}

* {
  scroll-behavior: smooth;
}

.homepage-container {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}

/* common */

.gradient-text {
  background: linear-gradient(92deg, #d43126 30.77%, #e69e37 152.46%, #e69e37 152.46%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.black-text {
  color: #000;
}

.custom-input {
  border: 1px solid #efefef;
  color: #606060;
}

.custom-input:hover {
  border: 1px solid #d43126;
  box-shadow: 0 0 1px #d43126;
  outline: none;
}

.custom-input:focus {
  border: 1px solid #d43126;
  box-shadow: 0 0 1px #d43126;
  outline: none;
}

.custom-input:active {
  border: 1px solid #d43126;
  box-shadow: 0 0 1px #d43126;
  outline: none;
}

.custom-input::placeholder {
  color: #d2d2d2;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}

/* header */

header {
  width: 100%;
  height: 92px;
  background: rgba(255, 255, 255, 0.97);
  backdrop-filter: blur(11px);
  z-index: 999;
  top: 0;
  position: fixed;
  display: flex;
  align-items: center;
}

/* .shrink {
  height: 83px;
  background: #fff;
  z-index: 1;
} */

.header-create-btn {
  width: 209px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  transition: 0.7s ease;
  z-index: 1;
  background: linear-gradient(92deg, #d43126 30.77%, #e69e37 152.46%, #e69e37 152.46%);
  border: 1px solid rgba(255, 255, 255, 0.97);
  border-radius: 16px;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}

.header-create-btn:before,
.header-create-btn:after {
  background: linear-gradient(92deg, #e69e37 30.77%, #d43126 152.46%, #d43126 152.46%);

  transition: 0.7s ease;
  content: "";
  position: absolute;
  z-index: -1;
  border-radius: 16px;
  border: 1px solid transparent;
}

.middle-out:before {
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%;
}

.middle-out:hover:before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.header-login-btn {
  width: 132px;
  height: 56px;
  position: relative;
  transition: all 1.5s;
  overflow: hidden;
  transition: all 0.3s linear;
  background: #fafafa;
  border: 1px solid #c1c1c1;
  border-radius: 16px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  line-height: normal;
}

.header-login-btn .header-login-btn-inner {
  position: relative;
  z-index: 99;
}

.header-login-btn:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(92deg, #d43126 30.77%, #e69e37 152.46%, #e69e37 152.46%);
  transition: all 0.6s;
  color: #fff;
}

.header-login-btn:hover {
  color: #fff;
}

.header-login-btn:hover:after {
  width: 100%;
  background: linear-gradient(92deg, #d43126 30.77%, #e69e37 152.46%, #e69e37 152.46%);
  color: #fff;
}

/* login */

.login-btn {
  background: linear-gradient(92deg, #d43126 30.77%, #e69e37 152.46%, #e69e37 152.46%);
  color: #fff;
  line-height: 29.54px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  /* transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  transition-delay: 50ms; */
}

.login-btn:hover {
  background: transparent;
  border: 1px solid #d43126;
  color: #d43126;
}

.signup-checkbox
  :where(.css-dev-only-do-not-override-amq5gd).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: #d43126;
}

.signup-checkbox :where(.css-dev-only-do-not-override-amq5gd).ant-checkbox .ant-checkbox-inner {
  border: 1px solid #d9d9d9;
}

.signup-checkbox
  :where(.css-dev-only-do-not-override-amq5gd).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-amq5gd).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #d43126;
}

.signup-checkbox :where(.css-dev-only-do-not-override-amq5gd).ant-checkbox-checked .ant-checkbox-inner {
  background-color: #d43126;
}

/* profile */

.profile-custom-vh {
  height: calc(100vh - 90px - 78px);
}

.profile-stepper-title {
  color: #000;
  font-style: normal;
  line-height: 58.22px;
  letter-spacing: 0.591px;
  text-transform: uppercase;
}

.profile-stepper-description {
  color: #000;
  text-align: center;
  font-style: normal;
  line-height: 23.691px;
  letter-spacing: 0.241px;
}

.profile-stepper-count {
  color: #000;
  text-align: center;
  font-style: normal;
  line-height: 29.54px;
  letter-spacing: 0.3px;
}

.profile-stepper-number {
  border-radius: 14.248px;
  border: 1px solid rgb(221, 221, 221, 0.7);
  background: rgba(251, 251, 251, 0.97);
  font-style: normal;
  line-height: 35.448px;
  letter-spacing: 0.36px;
}

.profile-stepper-number-active {
  border-radius: 14.248px;
  border: 1px solid rgb(212, 49, 38, 0.7);
  background: rgba(251, 251, 251, 0.97);
  font-style: normal;
  line-height: 35.448px;
  letter-spacing: 0.36px;
}

.profile-stepper-number-mobile {
  border-radius: 9px;
  border: 1px solid rgba(221, 221, 221, 0.505);
  background: rgba(251, 251, 251, 0.97);
  font-size: 13.2px;
  font-style: normal;
  line-height: 19.496px;
  letter-spacing: 0.198px;
  width: 33px;
  height: 33px;
}

.profile-stepper-number-mobile-active {
  border-radius: 13px;
  border: 1px solid rgb(212, 49, 38, 0.7);
  background: rgba(251, 251, 251, 0.97);
  font-size: 19px;
  font-style: normal;
  line-height: 28.399px;
  letter-spacing: 0.288px;
  width: 48.069px;
  height: 48.069px;
}

.profile-stepper-number-mobile-line {
  border-radius: 9px;
  border: 1px solid rgb(212, 49, 38, 0.7);
  background: rgba(251, 251, 251, 0.97);
  font-size: 13.2px;
  font-style: normal;
  line-height: 19.496px;
  letter-spacing: 0.198px;
  width: 33px;
  height: 33px;
}

.step-active-line {
  background: linear-gradient(92deg, #d43126 30.77%, #e69e37 152.46%, #e69e37 152.46%);
  opacity: 0.7;
}

.profile-step-form-heading {
  color: #000;
  text-align: center;
  font-style: normal;
  line-height: 47.264px;
  letter-spacing: 0.48px;
}

.profile-continue-btn {
  background: linear-gradient(92deg, #d43126 30.77%, #e69e37 152.46%, #e69e37 152.46%);
  color: #fff;
  font-style: normal;
  line-height: 29.54px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.profile-continue-btn:hover {
  background: transparent;
  border: 1px solid #d43126;
  color: #d43126;
}

.profile-upload-button {
  border: 1px solid #efefef;
  color: black;
  background-color: white;
  width: 179px;
  height: 179px;
  border-radius: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* .profile-popover-wrapper */

/* .profile-upload-button input[type="file"] {
  display: none;
} */

.profile-interest-ul {
  border-radius: 14.248px;
  border: 1px solid rgb(221, 221, 221, 0.7);
  background: rgba(251, 251, 251, 0.97);
  display: inline-flex;
  padding: 13px 6px;
  align-items: center;
}

.active-profile-sidebar {
  margin-top: 90px;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 90px);
  background: #fcfcfc;
  transition: all 0.5s;
}

.not-active-profile-sidebar {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: -100%;
  width: 100%;
  background: #fcfcfc;
  transition: all 0.5s;
}

.profile-sidebar-inner {
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.inputs-z {
  position: relative;
  z-index: 99;
}

.applyHoverOnImages:hover img {
  filter: grayscale(1) invert(1);
}

.create-collection-modal .ant-modal-content {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.select-collection-select {
  height: 70px;
  width: 100%;
}
.select-collection-select .ant-select-selector {
  border-radius: 16px;
  border: 1px solid #efefef !important;
  padding-left: 28px !important;
  padding-right: 28px !important;
}
.select-collection-select.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: #efefef;
}
.select-collection-select.ant-select-focused .ant-select-selector {
  border-color: #efefef !important;
}
.select-collection-select .ant-select-selection-placeholder {
  color: #bdbdbd;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.popover-profile-info {
}
/* dashboard */

.dashboard-custom-vh {
  height: calc(100vh - 92px);
}

.dashboard-tabs-wrapper {
  background: rgba(255, 255, 255, 0.97);
  backdrop-filter: blur(11px);
  height: 82px;
}

.dashboard-tabs-wrapper-scroll {
  background: rgba(255, 255, 255, 0.97);
  backdrop-filter: blur(11px);
  height: 82px;
}

/* story tabs */

.dahboard-story-custom-vh {
  height: calc(100vh - 92px - 82px);
}

.dashboard-story-title {
  text-align: center;
  font-style: normal;
  line-height: 94.528px;
  letter-spacing: 0.96px;
  text-transform: uppercase;
  background: linear-gradient(92deg, #d43126 30.77%, #e69e37 124.95%, #e69e37 152.46%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dashboard-story-subtitle {
  color: #000;
  font-style: normal;
  line-height: 147.7%;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.story-custom-input {
  border-radius: 18.047px;
  border: 1px solid #ddd;
  background: rgba(255, 255, 255, 0.97);
  color: #606060;
  line-height: normal;
  text-transform: uppercase;
}
.grey-input input {
  color: #707070;
}
.story-custom-input:hover {
  border: 1px solid #d43126;
  box-shadow: 0 0 1px #d43126;
  outline: none;
}

.story-custom-input:focus {
  border: 1px solid #d43126;
  box-shadow: 0 0 1px #d43126;
  outline: none;
}

.story-custom-input:active {
  border: 1px solid #d43126;
  box-shadow: 0 0 1px #d43126;
  outline: none;
}

.story-custom-input::placeholder {
  color: #bdbdbd;
  font-style: normal;
  line-height: normal;
}

.story-generate-btn {
  border-radius: 18.05px;
  border: 1px solid #d43126;
  background: rgba(255, 255, 255, 0.97);
  text-align: center;
  font-style: normal;
  line-height: 26.586px;
  letter-spacing: 0.27px;
  text-transform: uppercase;
}

.story-generate-btn-text {
  background: linear-gradient(92deg, #d43126 30.77%, #e69e37 152.46%, #e69e37 152.46%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.story-ideas-btn {
  border-radius: 22px;
  border: 1px solid #ddd;
  border-left: transparent;
  background: rgba(255, 255, 255, 0.97);
  font-style: normal;
  line-height: normal;
}

.story-idea-inner {
  border-radius: 18.111px;
  border: 1px solid #ddd;
  background: rgba(241, 241, 241, 0.97);
}

.story-idea-inner {
  border-radius: 14.036px;
  border: 1px solid rgb(221, 221, 221, 0.7);
  background: rgba(241, 241, 241, 0.97);
}

.story-idea-outer {
  border-radius: 22.212px;
  border: 1.231px solid #ddd;
  background: rgba(255, 255, 255, 0.97);
}

.header-user-btn {
  border-radius: 16px;
  border: 1px solid #e4e4e4;
  border-left: transparent;
  background: #fafafa;
  color: #000;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
}

.header-user-outer {
  border-radius: 18.047px;
  border: 1px solid #ddd;
  background: rgba(255, 255, 255, 0.97);
}

.header-user-inner {
  border-radius: 14.715px;
  border: 1px solid #ddd;
}

.header-toggler-btn {
  border-radius: 16px;
  border: 1px solid #c1c1c1;
  background: #fafafa;
}

.mobile-dashboard-tab-active {
  background: linear-gradient(92deg, #d43126 30.77%, #e69e37 152.46%, #e69e37 152.46%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.profile-switch-btn {
  border-radius: 15px;
  border: 1px solid #ddd;
  background: rgba(255, 255, 255, 0.97);
  color: #000;
  text-align: center;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
}

.profile-switch-btn:hover {
  border: 1px solid #ef4136;
  background: rgba(255, 255, 255, 0.97);
  color: #000;
}

.profile-dropdown-menu {
  color: #000;
  font-style: normal;
  line-height: 26.586px;
  letter-spacing: 0.27px;
  text-transform: uppercase;
}

.dashboard-profile-sidebar-inner {
  height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
}

/* .header-popover */

.header-popover-inner-menu {
  border-radius: 16px;
  border: 1px solid #e4e4e4;
  /* border-bottom: transparent;
  border-left: transparent;
  border-right: transparent; */
  background: #fff;
  margin-left: -1px;
  margin-right: -1px;
  margin-bottom: -1px;
}

.story-idea-input-wrapper {
  border-radius: 18.047px;
  border: 1px solid #ddd;
}

.story-idea-input {
  color: #606060;
  font-style: normal;
  line-height: normal;
  background-color: #fcfcfc;
  resize: none;
}

.story-idea-input:hover {
  border: 1px solid transparent;
  box-shadow: 0 0 1px transparent;
  outline: none;
}

.story-idea-input:focus {
  border: 1px solid transparent;
  box-shadow: 0 0 1px transparent;
  outline: none;
}

.story-idea-input:active {
  border: 1px solid transparent;
  box-shadow: 0 0 1px transparent;
  outline: none;
}

.story-idea-input::placeholder {
  color: #bdbdbd;
  font-style: normal;
  line-height: normal;
}

/* genearte story */

.generate-story-generate-btn {
  border-radius: 18.047px;
  border: 1px solid #d43126;
  background: rgba(255, 255, 255, 0.97);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  line-height: 26.586px;
  letter-spacing: 0.27px;
  text-transform: uppercase;
}

.generate-story-custom-input {
  border: 1px solid #ddd;
  background: rgba(255, 255, 255, 0.97);
  color: #000000;
  text-transform: uppercase;
}

.generate-story-custom-input:hover {
  border: 1px solid #d43126;
  box-shadow: 0 0 1px #d43126;
  outline: none;
}

.generate-story-custom-input:focus {
  border: 1px solid #d43126;
  box-shadow: 0 0 1px #d43126;
  outline: none;
}

.generate-story-custom-input:active {
  border: 1px solid #d43126;
  box-shadow: 0 0 1px #d43126;
  outline: none;
}

.generate-story-custom-input::placeholder {
  color: #bdbdbd;
}

.generate-section-left {
  border-radius: 16.805px;
  border: 1px solid rgba(221, 221, 221, 0.931);
  background: #fff;
}

.generate-chapter-left {
  border-radius: 12.803px;
  border: 1px solid rgba(221, 221, 221, 0.709);
  background: #fff;
}

.generate-section-right {
  border-radius: 18.047px;
  border: 1px solid #ddd;
  background: rgba(251, 251, 251, 0.97);
}

.generate-right-icons-wrapper {
  border-radius: 18.047px;
  border: 1px solid #ddd;
  background: rgba(251, 251, 251, 0.97);
}

.generate-right-icons-wrapper-active {
  border-radius: 18.047px;
  border: 1px solid #d43126;
  background: rgba(251, 251, 251, 0.97);
}

.dahboard-generate-story-custom-vh {
  height: calc(100vh - 92px - 82px);
}

/* faq */

.support-center-title {
  color: #000;
  text-align: center;
  font-style: normal;
  line-height: 35.448px;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.support-faq-title {
  color: #000;
  text-align: center;
  font-style: normal;
  line-height: 41.176px;
  letter-spacing: 0.418px;
}

.support-comtact-wrapper {
  border-radius: 12.606px;
  border: 1px solid rgba(227, 227, 227, 0.6);
  background: #fff;
}

.support-contact-custom-input {
  border-radius: 15.722px;
  border: 1px solid rgba(221, 221, 221, 0.8);
  background: rgba(252, 252, 252, 0.97);
  color: #000000;
}

.support-contact-custom-input:hover {
  border: 1px solid #d43126;
  box-shadow: 0 0 1px #d43126;
  outline: none;
}

.support-contact-custom-input:focus {
  border: 1px solid #d43126;
  box-shadow: 0 0 1px #d43126;
  outline: none;
}

.support-contact-custom-input:active {
  border: 1px solid #d43126;
  box-shadow: 0 0 1px #d43126;
  outline: none;
}

.support-contact-custom-input::placeholder {
  color: #bdbdbd;
  font-style: normal;
  line-height: normal;
}

.support-contact-btn {
  border-radius: 15.725px;
  border: 0.871px solid #d43126;
  background: rgba(255, 255, 255, 0.97);
  color: #ef4136;
  font-style: normal;
  line-height: 23.161px;
  letter-spacing: 0.235px;
  text-transform: uppercase;
}

.support-contact-btn:hover {
  border: 0.871px solid #d43126;
  background: #d43126;
  color: #fff;
}

/* membership */

.membership-title {
  color: #000;
  font-style: normal;
  line-height: 29.54px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.membership-profile-card {
  border-radius: 14.47px;
  border: 1px solid rgba(221, 221, 221, 0.802);
}

.membershiop-user-icon {
  width: 93px;
  height: 93px;
  border-radius: 11px;
}

.membership-profile-username {
  color: #000;
  font-style: normal;
  line-height: 28.816px;
  letter-spacing: 0.293px;
  text-transform: uppercase;
}

.membership-profile-type {
  color: #818181;
  font-style: normal;
  line-height: 19.211px;
  letter-spacing: 0.195px;
  text-transform: uppercase;
}

.profile-active-plan-title {
  color: #000;
  font-style: normal;
  line-height: 29.54px;
  letter-spacing: 0.3px;
}

.profile-upgrade-plan-title {
  color: #0088c2;
  font-style: normal;
  line-height: 23.632px;
  letter-spacing: 0.24px;
}

.profile-available-plan-title {
  color: #000;
  font-style: normal;
  line-height: 23.632px;
  letter-spacing: 0.24px;
}

.profile-plan-packages {
  width: 100%;
  min-height: 120.962px;
  border-radius: 13.568px;
  border: 1px solid rgba(221, 221, 221, 0.752);
  background: #fff;
}

.profile-plan-packages-active {
  width: 100%;
  min-height: 120.962px;
  border-radius: 13.568px;
  border: 1px solid rgba(212, 49, 38, 0.752);
  background: #fff;
}

.profile-plan-package-title {
  font-style: normal;
  line-height: 33.239px;
  letter-spacing: 0.338px;
  text-transform: uppercase;
}

.profile-plan-package-price-title {
  color: #000;
  font-style: normal;
  line-height: 27.699px;
  letter-spacing: 0.281px;
  text-transform: uppercase;
}

.profile-plan-package-price-description {
  color: #000;
  font-style: normal;
  font-weight: 400;
  line-height: 22.16px;
  letter-spacing: 0.225px;
}

.profile-plan-package-upgrade-btn-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.profile-plan-package-upgrade-btn {
  width: 168px;
  height: 45px;
  border-radius: 12px;
  border: 1px solid #c1c1c1;
  background: linear-gradient(92deg, #d43126 30.77%, #e69e37 152.46%, #e69e37 152.46%);
  color: #fff;
  font-style: normal;
  line-height: 23.632px;
  letter-spacing: 0.24px;
  text-transform: uppercase;
}

.profile-plan-package-upgrade-btn:hover {
  background: transparent;
  border: 1px solid #ef4136;
  color: #ef4136;
}

.profile-plan-active-title {
  color: #000;
  font-style: normal;
  line-height: 23.632px;
  letter-spacing: 0.24px;
  text-transform: uppercase;
}

.profile-plan-active-sub-title {
  font-style: normal;
  line-height: 33.239px;
  letter-spacing: 0.338px;
  text-transform: uppercase;
  background: linear-gradient(92deg, #d43126 30.77%, #e69e37 152.46%, #e69e37 152.46%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.profile-plan-active-date {
  color: #818181;
  font-style: normal;
  line-height: 23.632px;
  letter-spacing: 0.24px;
  text-transform: uppercase;
}

/* upgrade membership */

.upgrade-membership-custom-vh {
  height: calc(100vh - 90px - 78px);
}

.upgrade-membership-title {
  background-color: #fcfcfc;
  color: #000;
  text-align: center;
  font-style: normal;
  line-height: 29.54px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.upgrade-membership-plan-title {
  color: #000;
  font-style: normal;
  line-height: 58.22px;
  letter-spacing: 0.591px;
  text-transform: uppercase;
}

.upgrade-membership-plan-title-text {
  background: linear-gradient(92deg, #d43126 30.77%, #e69e37 152.46%, #e69e37 152.46%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  line-height: 58.22px;
  letter-spacing: 0.591px;
  text-transform: uppercase;
}

.upgrade-membership-plan-description {
  color: #000;
  font-style: normal;
  font-weight: 400;
  line-height: 23.691px;
  letter-spacing: 0.241px;
}

.upgrade-enchaters {
  color: #000;
  font-style: normal;
  line-height: 35.448px;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.upgrade-enchaters-per-month {
  color: #000;
  font-style: normal;
  line-height: 29.54px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.upgrade-enchaters-list {
  color: #000;
  font-style: normal;
  line-height: 23.632px;
  letter-spacing: 0.24px;
}

.upgrade-plan-active-border {
  border: 1px solid rgba(212, 49, 38, 0.802);
}

.upgrade-plan-not-active-border {
  border: 1px solid rgba(221, 221, 221, 0.802);
}

.radio-wrapper {
  border-radius: 14.47px;
  background: #fff;
  display: flex;
  align-items: center;
  width: 100%;
}

input[type="radio"] {
  display: none;
}

.radio-custom-label {
  cursor: pointer;
  position: relative;
}

.radio-custom-label::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 1px solid #000000;
  border-radius: 50%;
  top: 20px;
  left: 20;
  transform: translateY(-50%);
  transition: border-color 400ms ease;
}

.radio-custom-label::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: linear-gradient(92deg, #d43126 30.77%, #e69e37 152.46%, #e69e37 152.46%);
  border: 1px solid rgba(212, 49, 38, 0.802);
  border-radius: 50%;
  top: 20px;
  left: 20;
  transform: translateY(-50%) scale(0);
  transition: transform 400ms ease;
}

input[type="radio"]:checked + .radio-custom-label::before {
  border-color: #d43126;
}

input[type="radio"]:checked + .radio-custom-label::after {
  transform: translateY(-50%) scale(0.55);
}

.upgrade-process-btn {
  border-radius: 14.47px;
  background: linear-gradient(92deg, #d43126 30.77%, #e69e37 152.46%, #e69e37 152.46%);
  color: #fff;
  font-style: normal;
  line-height: 29.54px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  border: 1px solid transparent;
}

.upgrade-process-btn:hover {
  background: transparent;
  color: #d43126;
  border: 1px solid #d43126;
}

/* profile info */

.info-top-div {
  position: relative;
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}

.info-top-div::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background: linear-gradient(92deg, #d43126 30.77%, #e69e37 152.46%, #e69e37 152.46%);
  backdrop-filter: blur(38px);
  color: #000;
}

.my-info-card {
  border-radius: 14.47px;
  border: 1px solid rgba(221, 221, 221, 0.802);
  background: #fff;
}

.profile-interest-btn {
  border-radius: 14.248px;
  border: 1px solid rgba(221, 221, 221, 0.789);
  background: rgba(251, 251, 251, 0.97);
  padding: 14px 6px;
  display: flex;
  justify-content: space-between;
  gap: 7px;
  align-items: center;
}

.profile-info-custom-input {
  border: 1px solid rgba(0, 0, 0, 0.23);
  color: #000000;
}

.profile-info-custom-input:hover {
  border: 1px solid #d43126;
  box-shadow: 0 0 1px #d43126;
  outline: none;
}

.profile-info-custom-input:focus {
  border: 1px solid #d43126;
  box-shadow: 0 0 1px #d43126;
  outline: none;
}

.profile-info-custom-input:active {
  border: 1px solid #d43126;
  box-shadow: 0 0 1px #d43126;
  outline: none;
}

.profile-info-custom-input::placeholder {
  color: #bdbdbd;
  font-style: normal;
  line-height: normal;
}

.profile-info-custom-vh {
  height: calc(100vh - 92px - 82px);
}

/* manage account */

.manage-add-another-profile-btn {
  border-radius: 18.047px;
  border: 1px solid #ebebeb;
  background: linear-gradient(92deg, #d43126 30.77%, #e69e37 152.46%, #e69e37 152.46%);
  color: #fff;
  text-align: center;
  font-style: normal;
  line-height: normal;
}

.manage-add-another-profile-btn:hover {
  border: 1px solid #d43126;
  background: transparent;
  color: #d43126;
}

.manage-account-card {
  border-radius: 14.47px;
  border: 1px solid rgba(221, 221, 221, 0.802);
  background: #fdfdfd;
}

.manage-account-card-username {
  color: #000;
  font-style: normal;
  line-height: 28.816px;
  letter-spacing: 0.293px;
  text-transform: uppercase;
}

.manage-account-card-profile-type {
  color: #818181;
  font-style: normal;
  line-height: 19.211px;
  letter-spacing: 0.195px;
  text-transform: uppercase;
}

.manage-account-user-input {
  border-radius: 18.047px;
  border: 1px solid #ebebeb;
  background: rgba(251, 251, 251, 0.97);
  color: #000000;
}

.manage-account-user-input:hover {
  border: 1px solid #d43126;
  box-shadow: 0 0 1px #d43126;
  outline: none;
}

.manage-account-user-input:focus {
  border: 1px solid #d43126;
  box-shadow: 0 0 1px #d43126;
  outline: none;
}

.manage-account-user-input:active {
  border: 1px solid #d43126;
  box-shadow: 0 0 1px #d43126;
  outline: none;
}

.manage-account-user-input::placeholder {
  color: #bdbdbd;
  font-style: normal;
  line-height: normal;
}

.manage-account-change-password-btn {
  border: 1px solid rgba(212, 49, 38, 0.796);
  color: #d43126;
  background: transparent;
}

.manage-account-change-password-btn:hover {
  border: 1px solid rgba(212, 49, 38, 0.796);
  color: #fff;
  background: #d43126;
}

.manage-payemnt-method-title {
  color: #000;
  font-style: normal;
  line-height: 29.54px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.manage-payment-method-card {
  border: 1px solid rgba(221, 221, 221, 0.802);
}

.manage-visa-card-number {
  color: #000;
  font-style: normal;
  line-height: 29.934px;
  letter-spacing: 0.304px;
  text-transform: uppercase;
}

.manage-change-payment-method-btn {
  border: 1px solid #c1c1c1;
  background: #fafafa;
  color: #000;
  font-style: normal;
  line-height: 23.632px;
  letter-spacing: 0.24px;
}

.manage-large-pagination
  :where(.css-dev-only-do-not-override-amq5gd).ant-pagination.ant-pagination-simple
  .ant-pagination-simple-pager
  input {
  border: 1px solid transparent;
  margin-inline-end: 0px;
  padding: 0 0px;
}

.manage-large-pagination
  :where(.css-dev-only-do-not-override-amq5gd).ant-pagination.ant-pagination-simple
  .ant-pagination-simple-pager {
  display: inline-block;
  margin-inline-end: 20px;
  margin-top: 16px;
}

.pagination-custom-arrows-wrapper {
  border-radius: 18.047px;
  border: 1px solid #ddd;
  background: rgba(255, 255, 255, 0.97);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* generate story section */

.generate-chapter-center-section {
  border-radius: 14.47px;
  border: 1px solid rgba(221, 221, 221, 0.802);
  background: #fff;
}

/* library tab */

.library-left-sidebar {
  border-radius: 18.047px;
  border: 1px solid #e4e4e4;
  background: #fff;
  position: relative;
}

.library-add-collection-btn {
  border-radius: 18.047px;
  border: 1px solid #ddd;
  background: rgba(255, 255, 255, 0.97);
  color: #000;
  font-style: normal;
  line-height: 26.586px;
  letter-spacing: 0.27px;
  text-transform: uppercase;
}

.library-add-collection-icon {
  border-radius: 14.715px;
  border: 1px solid rgba(221, 221, 221, 0.815);
  background: rgba(241, 241, 241, 0.97);
}

.library-collection-tab-icon {
  border-radius: 18.047px;
  border: 1px solid #ddd;
  background: rgba(255, 255, 255, 0.97);
}

.library-collection-tab-icon-mbl {
  border-radius: 18.047px;
  border-right: 1px solid #ddd;
  background: rgba(255, 255, 255, 0.97);
}

.library-tabs-active-border {
  position: relative;
}

.library-tabs-active-border::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background: linear-gradient(92deg, #d43126 30.77%, #e69e37 152.46%, #e69e37 152.46%);
}

.library-tabs-non-active-border {
  border-left: 1px solid transparent;
}

.library-title-description {
  color: #737373;
  font-style: normal;
  line-height: 29.54px;
  letter-spacing: 0.3px;
}

.library-title-add-btn {
  color: #0088c2;
  font-style: normal;
  line-height: 28.344px;
  letter-spacing: 0.288px;
}

.library-collection-card {
  border-radius: 11.862px;
  border: 1px solid rgb(221, 221, 221, 0.791);
  background: #fdfdfd;
}

.library-collection-card-active {
  border-radius: 11.862px;
  border: 1px solid rgb(221, 221, 221, 0.791);
  background: #fdfdfd;
  box-shadow: 0px 3.16311px 3.16311px 0px rgba(0, 0, 0, 0.04);
}

.library-collection-card-title {
  color: #000;
  text-align: center;
  font-style: normal;
  line-height: 18.778px;
  letter-spacing: 0.191px;
}

.library-collection-card-description {
  color: #cbcbcb;
  text-align: center;
  font-style: normal;
  line-height: 15.184px;
  letter-spacing: 0.154px;
}

.sidebar-margin-custom {
  margin-top: -240px;
  /* margin-top: 30px; */
}

@media screen and (max-width: 639px) {
  .library-collection-card-title,
  .library-collection-card-description {
    text-align: left;
    width: 100%;
  }
}
@media screen and (max-width: 1023px) {
  .sidebar-margin-custom {
    margin-top: 30px;
  }
  .library-left-sidebar {
    max-width: 280px;
  }
}

.library-collection-wrapper-mbl {
  border-radius: 18.047px;
  border: 1px solid #efefef;
  background: rgba(255, 255, 255, 0.97);
}

.library-collection-inner-wrapper-mbl {
  border-radius: 18.047px;
  border-right: 1px solid #efefef;
  background: rgba(249, 249, 249, 0.97);
  padding: 11px 10px 11px 7px;
}

.library-collection-date-mbl {
  color: #cbcbcb;
  font-style: normal;
  line-height: 15.184px;
  letter-spacing: 0.154px;
}

/* forgot password */

.forgot-password-wrapper
  :where(.css-dev-only-do-not-override-amq5gd).ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #000;
  font-family: Montserrat;
  font-weight: 500;
}

.forgot-password-wrapper
  :where(.css-dev-only-do-not-override-amq5gd).ant-steps
  .ant-steps-item-process
  .ant-steps-item-icon {
  background-color: #d43126;
  border-color: #d43126;
}

.forgot-password-wrapper
  :where(.css-dev-only-do-not-override-amq5gd).ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon {
  background-color: transparent;
  border-color: #d43126;
}

.forgot-password-wrapper
  :where(.css-dev-only-do-not-override-amq5gd).ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: #d43126;
}

.forgot-password-wrapper
  :where(.css-dev-only-do-not-override-amq5gd).ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #d43126;
}

:where(.css-dev-only-do-not-override-amq5gd).ant-modal .ant-modal-close {
  color: #000000;
  top: 22px;
}

:where(.css-dev-only-do-not-override-amq5gd).ant-modal .ant-modal-content {
  border-radius: 16px;
  border: 1px solid #e4e4e4;
  background: #fff;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.05);
}

/* media queries */

@media screen and (min-width: 1920px) {
  .support-center-section-inner {
    background: rgba(255, 255, 255, 0.91);
    backdrop-filter: blur(38px);
    height: 116px;
  }

  .support-center-section-mbl {
    position: relative;
    width: 100%;
    height: 116px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
  }

  .support-center-section-mbl::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.08;
    background: linear-gradient(251deg, #fff 1.12%, #f6803b 48.73%, #fbaf40 98.05%);
    color: #000;
    /* background: rgba(255, 255, 255, 0.91);
    backdrop-filter: blur(38px); */
  }
  .upgrade-outer-wrapper {
    padding-left: 160px;
  }
  .upgrade-outer-inner-right-wrapper {
    padding-right: 160px;
  }
  .generate-chapter-center-section {
    width: 480px;
  }
}

@media screen and (max-width: 1919px) and (min-width: 1700px) {
  .support-center-section-inner {
    background: rgba(255, 255, 255, 0.91);
    backdrop-filter: blur(38px);
    height: 116px;
  }

  .support-center-section-mbl {
    position: relative;
    width: 100%;
    height: 116px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
  }

  .support-center-section-mbl::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.08;
    background: linear-gradient(251deg, #fff 1.12%, #f6803b 48.73%, #fbaf40 98.05%);
    color: #000;
    /* background: rgba(255, 255, 255, 0.91);
    backdrop-filter: blur(38px); */
  }
  .upgrade-outer-wrapper {
    padding-left: 130px;
  }
  .upgrade-outer-inner-right-wrapper {
    padding-right: 130px;
  }
  .generate-chapter-center-section {
    width: 400px;
  }
}

@media screen and (max-width: 1699px) and (min-width: 1536px) {
  .support-center-section-inner {
    background: rgba(255, 255, 255, 0.91);
    backdrop-filter: blur(38px);
    height: 116px;
  }

  .support-center-section-mbl {
    position: relative;
    width: 100%;
    height: 116px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
  }

  .support-center-section-mbl::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.08;
    background: linear-gradient(251deg, #fff 1.12%, #f6803b 48.73%, #fbaf40 98.05%);
    color: #000;
    /* background: rgba(255, 255, 255, 0.91);
    backdrop-filter: blur(38px); */
  }
  .upgrade-outer-wrapper {
    padding-left: 100px;
  }
  .upgrade-outer-inner-right-wrapper {
    padding-right: 100px;
  }
  .generate-chapter-center-section {
    width: 350px;
  }
}

@media screen and (max-width: 1535px) and (min-width: 1280px) {
  .sidebar-margin-custom {
    margin-top: -230px;
  }
  .header-login-btn {
    width: 130px;
    height: 50px;
  }
  .header-create-btn {
    width: 200px;
    height: 53px;
  }
  .support-center-section-inner {
    background: rgba(255, 255, 255, 0.91);
    backdrop-filter: blur(38px);
    height: 116px;
  }

  .support-center-section-mbl {
    position: relative;
    width: 100%;
    height: 116px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
  }

  .support-center-section-mbl::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.08;
    background: linear-gradient(251deg, #fff 1.12%, #f6803b 48.73%, #fbaf40 98.05%);
    color: #000;
    /* background: rgba(255, 255, 255, 0.91);
    backdrop-filter: blur(38px); */
  }
}

@media screen and (max-width: 1279px) and (min-width: 1024px) {
  .sidebar-margin-custom {
    margin-top: -220px;
  }
  .dahboard-generate-story-custom-vh {
    max-height: calc(100vh - 92px - 82px);
  }
  .header-login-btn {
    width: 125px;
    height: 48px;
  }
  .header-create-btn {
    width: 200px;
    height: 51px;
  }

  .support-center-section-inner {
    background: rgba(255, 255, 255, 0.91);
    backdrop-filter: blur(38px);
    height: 116px;
  }

  .support-center-section-mbl {
    position: relative;
    width: 100%;
    height: 116px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
  }

  .support-center-section-mbl::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.08;
    background: linear-gradient(251deg, #fff 1.12%, #f6803b 48.73%, #fbaf40 98.05%);
    color: #000;
    /* background: rgba(255, 255, 255, 0.91);
    backdrop-filter: blur(38px); */
  }
}

@media screen and (max-width: 1023px) and (min-width: 900px) {
  .generate-chapter-center-section {
    border-radius: 22px;
    border: 1px solid #e3e3e3;
    background: rgba(252, 252, 252, 0.79);
    backdrop-filter: blur(4px);
  }
  .manage-large-pagination
    :where(.css-dev-only-do-not-override-amq5gd).ant-pagination.ant-pagination-simple
    .ant-pagination-simple-pager {
    display: inline-block;
    margin-inline-end: 18px;
    margin-top: 6px;
  }
  .pagination-custom-arrows-wrapper {
    border-radius: 9.995px;
    border: 1px solid rgba(221, 221, 221, 0.554);
    background: rgba(255, 255, 255, 0.97);
  }
  .generate-left-custom-vh {
    height: calc(100vh - 92px - 78px - 78px - 200px);
  }
  .generate-chapter-left-custom-vh {
    height: calc(100vh - 92px - 78px - 78px - 150px);
  }
  .dahboard-generate-story-custom-vh {
    max-height: calc(100vh - 92px - 78px);
  }
  .generate-section-left {
    border-radius: 0px;
    border: 1px solid transparent;
    background: #fff;
  }
  .generate-chapter-left {
    border-radius: 0px;
    border: 1px solid transparent;
    background: #fff;
  }
  .generate-right-icons-wrapper {
    border-radius: 11.153px;
    border: 1px solid rgba(221, 221, 221, 0.618);
    background: rgba(251, 251, 251, 0.97);
  }
  .generate-right-icons-wrapper-active {
    border-radius: 11.153px;
    border: 1px solid rgba(212, 49, 38, 0.618);
    background: rgba(251, 251, 251, 0.97);
  }
  .generate-story-generate-btn {
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .custom-input::placeholder {
    font-size: 15px;
  }
  .login-btn {
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .profile-stepper-title {
    font-style: normal;
    line-height: 40.169px;
    letter-spacing: 0.408px;
    text-transform: uppercase;
  }
  .profile-stepper-description {
    line-height: 16.345px;
    letter-spacing: 0.166px;
  }
  .profile-step-form-heading {
    line-height: 29.54px;
    letter-spacing: 0.3px;
  }
  .profile-continue-btn {
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .profile-upload-button {
    border: 1px solid #efefef;
    width: 100%;
    height: 90px;
    border-radius: 16px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .profile-interest-ul {
    border-radius: 9.93px;
    border: 1px solid rgb(221, 221, 221, 0.5);
    background: rgba(251, 251, 251, 0.97);
    display: inline-flex;
    padding: 11.152px 4.182px;
    align-items: center;
  }
  header {
    height: 90px;
    background: #fcfcfc;
    backdrop-filter: none;
    /* z-index: 1; */
  }
  .dashboard-story-title {
    line-height: 55.913px;
    letter-spacing: 0.568px;
  }
  .dashboard-story-subtitle {
    line-height: 147.7%;
    letter-spacing: 0.6px;
  }
  .story-generate-btn {
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .story-idea-outer {
    border-radius: 17.214px;
    border: 1px solid rgb(221, 221, 221, 0.9);
  }
  .mobile-dashboard-tab-text-size {
    font-size: 14px;
  }
  .dashboard-custom-vh {
    height: calc(100vh - 90px - 78px);
  }
  .support-center-section-mbl {
    position: relative;
    width: 100%;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .support-center-section-mbl::after {
    position: absolute;
    content: "";
    opacity: 0.1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background: linear-gradient(92deg, #d43126 30.77%, #e69e37 152.46%, #e69e37 152.46%);
    backdrop-filter: blur(38px);
  }
  .support-center-title {
    color: #000;
    font-style: normal;
    line-height: 23.632px;
    letter-spacing: 0.24px;
    text-transform: uppercase;
  }
  .support-faq-title {
    color: #000;
    font-style: normal;
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .support-comtact-wrapper {
    border-radius: 14.47px;
    border: 1px solid rgba(227, 227, 227, 0.8);
    background: #fdfdfd;
  }
  .support-contact-custom-input {
    border-radius: 18.047px;
    border: 1px solid #ebebeb;
    background: rgba(251, 251, 251, 0.97);
  }
  .support-contact-btn {
    border-radius: 18.05px;
    border: 0.796px solid #d43126;
    color: #ef4136;
    background: #fff;
    font-style: normal;
    line-height: normal;
  }
  .profile-active-plan-title {
    color: #000;
    font-style: normal;
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .profile-plan-packages {
    height: auto;
  }
  .profile-plan-packages-active {
    height: auto;
  }
  .profile-plan-package-title {
    font-style: normal;
    line-height: 26.586px;
    letter-spacing: 0.27px;
    text-transform: uppercase;
  }
  .profile-plan-package-price-description {
    line-height: 20.678px;
    letter-spacing: 0.21px;
  }
  .profile-plan-active-sub-title {
    line-height: 26.586px;
    letter-spacing: 0.27px;
  }
  .profile-plan-active-date {
    line-height: 20.678px;
    letter-spacing: 0.21px;
  }
  .upgrade-membership-plan-title-text {
    line-height: 40.169px;
    letter-spacing: 0.408px;
  }
  .upgrade-membership-plan-description {
    color: #000;
    font-style: normal;
    font-weight: 400;
    line-height: 16.345px;
    letter-spacing: 0.166px;
  }
  .upgrade-process-btn {
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .profile-info-user-name {
    color: #000;
    font-style: normal;
    line-height: 28.816px;
    letter-spacing: 0.293px;
    text-transform: uppercase;
  }
  .profile-info-user-account {
    color: #818181;
    font-style: normal;
    line-height: 19.211px;
    letter-spacing: 0.195px;
    text-transform: uppercase;
  }
  .info-top-div {
    height: 85px;
  }
  .profile-interest-btn {
    border-radius: 9.93px;
    border: 1px solid rgba(221, 221, 221, 0.55);
    background: rgba(251, 251, 251, 0.97);
    padding: 11px 4px;
    display: flex;
    justify-content: space-between;
    gap: 4px;
    align-items: center;
  }
  .profile-info-preffered-theme {
    color: #000;
    font-style: normal;
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .profile-info-custom-input {
    border: 1px solid #efefef;
    color: #000000;
  }
  .profile-info-custom-vh {
    height: calc(100vh - 90px - 78px);
  }
  .manage-visa-card-number {
    line-height: 23.178px;
    letter-spacing: 0.235px;
  }
  :where(.css-dev-only-do-not-override-amq5gd).ant-modal .ant-modal-close {
    top: 20px;
  }
}

@media screen and (max-width: 899px) and (min-width: 768px) {
  .generate-chapter-center-section {
    border-radius: 22px;
    border: 1px solid #e3e3e3;
    background: rgba(252, 252, 252, 0.79);
    backdrop-filter: blur(4px);
  }
  .manage-large-pagination
    :where(.css-dev-only-do-not-override-amq5gd).ant-pagination.ant-pagination-simple
    .ant-pagination-simple-pager {
    display: inline-block;
    margin-inline-end: 18px;
    margin-top: 7px;
  }
  .pagination-custom-arrows-wrapper {
    border-radius: 9.995px;
    border: 1px solid rgba(221, 221, 221, 0.554);
    background: rgba(255, 255, 255, 0.97);
  }
  .generate-left-custom-vh {
    height: calc(100vh - 92px - 78px - 78px - 200px);
  }
  .generate-chapter-left-custom-vh {
    height: calc(100vh - 92px - 78px - 78px - 100px);
  }
  .dahboard-generate-story-custom-vh {
    max-height: calc(100vh - 92px - 78px);
  }
  .generate-section-left {
    border-radius: 0px;
    border: 1px solid transparent;
    background: #fff;
  }
  .generate-chapter-left {
    border-radius: 0px;
    border: 1px solid transparent;
    background: #fff;
  }
  .generate-right-icons-wrapper {
    border-radius: 11.153px;
    border: 1px solid rgba(221, 221, 221, 0.618);
    background: rgba(251, 251, 251, 0.97);
  }
  .generate-right-icons-wrapper-active {
    border-radius: 11.153px;
    border: 1px solid rgba(212, 49, 38, 0.618);
    background: rgba(251, 251, 251, 0.97);
  }
  .generate-story-generate-btn {
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .custom-input::placeholder {
    font-size: 15px;
  }
  .login-btn {
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .profile-stepper-title {
    font-style: normal;
    line-height: 40.169px;
    letter-spacing: 0.408px;
    text-transform: uppercase;
  }
  .profile-stepper-description {
    line-height: 16.345px;
    letter-spacing: 0.166px;
  }
  .profile-step-form-heading {
    line-height: 29.54px;
    letter-spacing: 0.3px;
  }
  .profile-continue-btn {
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .profile-upload-button {
    border: 1px solid #efefef;
    width: 100%;
    height: 90px;
    border-radius: 16px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .profile-interest-ul {
    border-radius: 9.93px;
    border: 1px solid rgb(221, 221, 221, 0.5);
    background: rgba(251, 251, 251, 0.97);
    display: inline-flex;
    padding: 11.152px 4.182px;
    align-items: center;
  }
  header {
    height: 90px;
    background: #fcfcfc;
    backdrop-filter: none;
    /* z-index: 1; */
  }
  .dashboard-story-title {
    line-height: 55.913px;
    letter-spacing: 0.568px;
  }
  .dashboard-story-subtitle {
    line-height: 147.7%;
    letter-spacing: 0.6px;
  }
  .story-generate-btn {
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .story-idea-outer {
    border-radius: 17.214px;
    border: 1px solid rgb(221, 221, 221, 0.9);
  }
  .mobile-dashboard-tab-text-size {
    font-size: 14px;
  }
  .dashboard-custom-vh {
    height: calc(100vh - 90px - 78px);
  }
  .support-center-section-mbl {
    position: relative;
    width: 100%;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .support-center-section-mbl::after {
    position: absolute;
    content: "";
    opacity: 0.1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background: linear-gradient(92deg, #d43126 30.77%, #e69e37 152.46%, #e69e37 152.46%);
    backdrop-filter: blur(38px);
  }
  .support-center-title {
    color: #000;
    font-style: normal;
    line-height: 23.632px;
    letter-spacing: 0.24px;
    text-transform: uppercase;
  }
  .support-faq-title {
    color: #000;
    font-style: normal;
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .support-comtact-wrapper {
    border-radius: 14.47px;
    border: 1px solid rgba(227, 227, 227, 0.8);
    background: #fdfdfd;
  }
  .support-contact-custom-input {
    border-radius: 18.047px;
    border: 1px solid #ebebeb;
    background: rgba(251, 251, 251, 0.97);
  }
  .support-contact-btn {
    border-radius: 18.05px;
    border: 0.796px solid #d43126;
    color: #ef4136;
    background: #fff;
    font-style: normal;
    line-height: normal;
  }
  .profile-active-plan-title {
    color: #000;
    font-style: normal;
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .profile-plan-packages {
    height: auto;
  }
  .profile-plan-packages-active {
    height: auto;
  }
  .profile-plan-package-title {
    font-style: normal;
    line-height: 26.586px;
    letter-spacing: 0.27px;
    text-transform: uppercase;
  }
  .profile-plan-package-price-description {
    line-height: 20.678px;
    letter-spacing: 0.21px;
  }
  .profile-plan-active-sub-title {
    line-height: 26.586px;
    letter-spacing: 0.27px;
  }
  .profile-plan-active-date {
    line-height: 20.678px;
    letter-spacing: 0.21px;
  }
  .upgrade-membership-plan-title-text {
    line-height: 40.169px;
    letter-spacing: 0.408px;
  }
  .upgrade-membership-plan-description {
    color: #000;
    font-style: normal;
    font-weight: 400;
    line-height: 16.345px;
    letter-spacing: 0.166px;
  }
  .upgrade-process-btn {
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .radio-custom-label::before {
    width: 20px;
    height: 20px;
    top: 27px;
    left: 8px;
  }
  .radio-custom-label::after {
    width: 20px;
    height: 20px;
    top: 27px;
    left: 8px;
  }
  .profile-info-user-name {
    color: #000;
    font-style: normal;
    line-height: 28.816px;
    letter-spacing: 0.293px;
    text-transform: uppercase;
  }
  .profile-info-user-account {
    color: #818181;
    font-style: normal;
    line-height: 19.211px;
    letter-spacing: 0.195px;
    text-transform: uppercase;
  }
  .info-top-div {
    height: 85px;
  }
  .profile-interest-btn {
    border-radius: 9.93px;
    border: 1px solid rgba(221, 221, 221, 0.55);
    background: rgba(251, 251, 251, 0.97);
    padding: 11px 4px;
    display: flex;
    justify-content: space-between;
    gap: 4px;
    align-items: center;
  }
  .profile-info-preffered-theme {
    color: #000;
    font-style: normal;
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .profile-info-custom-input {
    border: 1px solid #efefef;
    color: #000000;
  }
  .profile-info-custom-vh {
    height: calc(100vh - 90px - 78px);
  }
  .manage-visa-card-number {
    line-height: 23.178px;
    letter-spacing: 0.235px;
  }
  :where(.css-dev-only-do-not-override-amq5gd).ant-modal .ant-modal-close {
    top: 20px;
  }
}

@media screen and (max-width: 768px) and (min-width: 426px) {
}

@media screen and (max-width: 767px) and (min-width: 426px) {
  .generate-chapter-center-section {
    border-radius: 22px;
    border: 1px solid #e3e3e3;
    background: rgba(252, 252, 252, 0.79);
    backdrop-filter: blur(4px);
  }
  .manage-large-pagination
    :where(.css-dev-only-do-not-override-amq5gd).ant-pagination.ant-pagination-simple
    .ant-pagination-simple-pager {
    display: inline-block;
    margin-inline-end: 18px;
    margin-top: 6px;
  }
  .pagination-custom-arrows-wrapper {
    border-radius: 9.995px;
    border: 1px solid rgba(221, 221, 221, 0.554);
    background: rgba(255, 255, 255, 0.97);
  }
  .generate-left-custom-vh {
    height: calc(100vh - 92px - 78px - 78px - 200px);
  }
  .generate-chapter-left-custom-vh {
    height: calc(100vh - 92px - 78px - 78px - 180px);
  }
  .dahboard-generate-story-custom-vh {
    max-height: calc(100vh - 92px - 78px);
  }
  .generate-section-left {
    border-radius: 0px;
    border: 1px solid transparent;
    background: #fff;
  }
  .generate-chapter-left {
    border-radius: 0px;
    border: 1px solid transparent;
    background: #fff;
  }
  .generate-right-icons-wrapper {
    border-radius: 11.153px;
    border: 1px solid rgba(221, 221, 221, 0.618);
    background: rgba(251, 251, 251, 0.97);
  }
  .generate-right-icons-wrapper-active {
    border-radius: 11.153px;
    border: 1px solid rgba(212, 49, 38, 0.618);
    background: rgba(251, 251, 251, 0.97);
  }
  .generate-story-generate-btn {
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .custom-input::placeholder {
    font-size: 14px;
  }
  .login-btn {
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .profile-stepper-title {
    font-style: normal;
    line-height: 40.169px;
    letter-spacing: 0.408px;
    text-transform: uppercase;
  }
  .profile-stepper-description {
    line-height: 16.345px;
    letter-spacing: 0.166px;
  }
  .profile-step-form-heading {
    line-height: 29.54px;
    letter-spacing: 0.3px;
  }
  .profile-continue-btn {
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .profile-upload-button {
    border: 1px solid #efefef;
    width: 100%;
    height: 90px;
    border-radius: 16px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .profile-interest-ul {
    border-radius: 9.93px;
    border: 1px solid rgb(221, 221, 221, 0.5);
    background: rgba(251, 251, 251, 0.97);
    display: inline-flex;
    padding: 11.152px 4.182px;
    align-items: center;
  }
  header {
    height: 90px;
    background: #fcfcfc;
    backdrop-filter: none;
    /* z-index: 1; */
  }
  .dashboard-story-title {
    line-height: 55.913px;
    letter-spacing: 0.568px;
  }
  .dashboard-story-subtitle {
    line-height: 147.7%;
    letter-spacing: 0.6px;
  }
  .story-generate-btn {
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .story-idea-outer {
    border-radius: 17.214px;
    border: 1px solid rgb(221, 221, 221, 0.9);
  }
  .mobile-dashboard-tab-text-size {
    font-size: 14px;
  }
  .dashboard-custom-vh {
    height: calc(100vh - 90px - 78px);
  }
  .support-center-section-mbl {
    position: relative;
    width: 100%;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .support-center-section-mbl::after {
    position: absolute;
    content: "";
    opacity: 0.1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background: linear-gradient(92deg, #d43126 30.77%, #e69e37 152.46%, #e69e37 152.46%);
    backdrop-filter: blur(38px);
  }
  .support-center-title {
    color: #000;
    font-style: normal;
    line-height: 23.632px;
    letter-spacing: 0.24px;
    text-transform: uppercase;
  }
  .support-faq-title {
    color: #000;
    font-style: normal;
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .support-comtact-wrapper {
    border-radius: 14.47px;
    border: 1px solid rgba(227, 227, 227, 0.8);
    background: #fdfdfd;
  }
  .support-contact-custom-input {
    border-radius: 18.047px;
    border: 1px solid #ebebeb;
    background: rgba(251, 251, 251, 0.97);
  }
  .support-contact-btn {
    border-radius: 18.05px;
    border: 0.796px solid #d43126;
    color: #ef4136;
    background: #fff;
    font-style: normal;
    line-height: normal;
  }
  .profile-active-plan-title {
    color: #000;
    font-style: normal;
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .profile-plan-packages {
    height: auto;
  }
  .profile-plan-packages-active {
    height: auto;
  }
  .profile-plan-package-title {
    font-style: normal;
    line-height: 26.586px;
    letter-spacing: 0.27px;
    text-transform: uppercase;
  }
  .profile-plan-package-price-description {
    line-height: 20.678px;
    letter-spacing: 0.21px;
  }
  .profile-plan-active-sub-title {
    line-height: 26.586px;
    letter-spacing: 0.27px;
  }
  .profile-plan-active-date {
    line-height: 20.678px;
    letter-spacing: 0.21px;
  }
  .upgrade-membership-plan-title-text {
    line-height: 40.169px;
    letter-spacing: 0.408px;
  }
  .upgrade-membership-plan-description {
    color: #000;
    font-style: normal;
    font-weight: 400;
    line-height: 16.345px;
    letter-spacing: 0.166px;
  }
  .upgrade-process-btn {
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .radio-custom-label::before {
    width: 20px;
    height: 20px;
    top: 27px;
    left: 8px;
  }
  .radio-custom-label::after {
    width: 20px;
    height: 20px;
    top: 27px;
    left: 8px;
  }
  .profile-info-user-name {
    color: #000;
    font-style: normal;
    line-height: 28.816px;
    letter-spacing: 0.293px;
    text-transform: uppercase;
  }
  .profile-info-user-account {
    color: #818181;
    font-style: normal;
    line-height: 19.211px;
    letter-spacing: 0.195px;
    text-transform: uppercase;
  }
  .info-top-div {
    height: 85px;
  }
  .profile-interest-btn {
    border-radius: 9.93px;
    border: 1px solid rgba(221, 221, 221, 0.55);
    background: rgba(251, 251, 251, 0.97);
    padding: 11px 4px;
    display: flex;
    justify-content: space-between;
    gap: 4px;
    align-items: center;
  }
  .profile-info-preffered-theme {
    color: #000;
    font-style: normal;
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .profile-info-custom-input {
    border: 1px solid #efefef;
    color: #000000;
  }
  .profile-info-custom-vh {
    height: calc(100vh - 90px - 78px);
  }
  .manage-visa-card-number {
    line-height: 23.178px;
    letter-spacing: 0.235px;
  }
  :where(.css-dev-only-do-not-override-amq5gd).ant-modal .ant-modal-close {
    top: 20px;
  }
}

@media screen and (max-width: 425px) {
  .generate-chapter-center-section {
    border-radius: 22px;
    border: 1px solid #e3e3e3;
    background: rgba(252, 252, 252, 0.79);
    backdrop-filter: blur(4px);
  }
  .manage-large-pagination
    :where(.css-dev-only-do-not-override-amq5gd).ant-pagination.ant-pagination-simple
    .ant-pagination-simple-pager {
    display: inline-block;
    margin-inline-end: 18px;
    margin-top: 6px;
  }
  .pagination-custom-arrows-wrapper {
    border-radius: 9.995px;
    border: 1px solid rgba(221, 221, 221, 0.554);
    background: rgba(255, 255, 255, 0.97);
  }
  .generate-left-custom-vh {
    height: calc(100vh - 92px - 78px - 78px - 200px);
  }
  .generate-chapter-left-custom-vh {
    height: calc(100vh - 92px - 78px - 78px - 170px);
  }
  .dahboard-generate-story-custom-vh {
    max-height: calc(100vh - 92px - 78px);
  }
  .generate-section-left {
    border-radius: 0px;
    border: 1px solid transparent;
    background: #fff;
  }
  .generate-chapter-left {
    border-radius: 0px;
    border: 1px solid transparent;
    background: #fff;
  }
  .generate-right-icons-wrapper {
    border-radius: 11.153px;
    border: 1px solid rgba(221, 221, 221, 0.618);
    background: rgba(251, 251, 251, 0.97);
  }
  .generate-right-icons-wrapper-active {
    border-radius: 11.153px;
    border: 1px solid rgba(212, 49, 38, 0.618);
    background: rgba(251, 251, 251, 0.97);
  }
  .generate-story-generate-btn {
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .custom-input::placeholder {
    font-size: 14px;
  }
  .login-btn {
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .profile-stepper-title {
    font-style: normal;
    line-height: 40.169px;
    letter-spacing: 0.408px;
    text-transform: uppercase;
  }
  .profile-stepper-description {
    line-height: 16.345px;
    letter-spacing: 0.166px;
  }
  .profile-step-form-heading {
    line-height: 29.54px;
    letter-spacing: 0.3px;
  }
  .profile-continue-btn {
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .profile-upload-button {
    border: 1px solid #efefef;
    width: 100%;
    height: 90px;
    border-radius: 16px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .profile-interest-ul {
    border-radius: 9.93px;
    border: 1px solid rgb(221, 221, 221, 0.5);
    background: rgba(251, 251, 251, 0.97);
    display: inline-flex;
    padding: 11.152px 4.182px;
    align-items: center;
  }
  header {
    height: 90px;
    background: #fcfcfc;
    backdrop-filter: none;
    /* z-index: 1; */
  }
  .dashboard-story-title {
    line-height: 55.913px;
    letter-spacing: 0.568px;
  }
  .dashboard-story-subtitle {
    line-height: 147.7%;
    letter-spacing: 0.6px;
  }
  .story-generate-btn {
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .story-idea-outer {
    border-radius: 17.214px;
    border: 1px solid rgb(221, 221, 221, 0.9);
  }
  .mobile-dashboard-tab-text-size {
    font-size: 14px;
  }
  .dashboard-custom-vh {
    height: calc(100vh - 90px - 78px);
  }
  .support-center-section-mbl {
    position: relative;
    width: 100%;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .support-center-section-mbl::after {
    position: absolute;
    content: "";
    opacity: 0.1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background: linear-gradient(92deg, #d43126 30.77%, #e69e37 152.46%, #e69e37 152.46%);
    backdrop-filter: blur(38px);
  }

  .support-center-title {
    color: #000;
    font-style: normal;
    line-height: 23.632px;
    letter-spacing: 0.24px;
    text-transform: uppercase;
  }
  .support-faq-title {
    color: #000;
    font-style: normal;
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .support-comtact-wrapper {
    border-radius: 14.47px;
    border: 1px solid rgba(227, 227, 227, 0.8);
    background: #fdfdfd;
  }
  .support-contact-custom-input {
    border-radius: 18.047px;
    border: 1px solid #ebebeb;
    background: rgba(251, 251, 251, 0.97);
  }
  .support-contact-btn {
    border-radius: 18.05px;
    border: 0.796px solid #d43126;
    color: #ef4136;
    background: #fff;
    font-style: normal;
    line-height: normal;
  }
  .profile-active-plan-title {
    color: #000;
    font-style: normal;
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .profile-plan-packages {
    height: auto;
  }
  .profile-plan-packages-active {
    height: auto;
  }
  .profile-plan-package-title {
    font-style: normal;
    line-height: 26.586px;
    letter-spacing: 0.27px;
    text-transform: uppercase;
  }
  .profile-plan-package-price-description {
    line-height: 20.678px;
    letter-spacing: 0.21px;
  }
  .profile-plan-active-sub-title {
    line-height: 26.586px;
    letter-spacing: 0.27px;
  }
  .profile-plan-active-date {
    line-height: 20.678px;
    letter-spacing: 0.21px;
  }
  .upgrade-membership-plan-title-text {
    line-height: 40.169px;
    letter-spacing: 0.408px;
  }
  .upgrade-membership-plan-description {
    color: #000;
    font-style: normal;
    font-weight: 400;
    line-height: 16.345px;
    letter-spacing: 0.166px;
  }
  .upgrade-process-btn {
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .radio-custom-label::before {
    width: 20px;
    height: 20px;
    top: 27px;
    left: 8px;
  }
  .radio-custom-label::after {
    width: 20px;
    height: 20px;
    top: 27px;
    left: 8px;
  }
  .profile-info-user-name {
    color: #000;
    font-style: normal;
    line-height: 28.816px;
    letter-spacing: 0.293px;
    text-transform: uppercase;
  }
  .profile-info-user-account {
    color: #818181;
    font-style: normal;
    line-height: 19.211px;
    letter-spacing: 0.195px;
    text-transform: uppercase;
  }
  .info-top-div {
    height: 85px;
  }
  .profile-interest-btn {
    border-radius: 9.93px;
    border: 1px solid rgba(221, 221, 221, 0.55);
    background: rgba(251, 251, 251, 0.97);
    padding: 11px 4px;
    display: flex;
    justify-content: space-between;
    gap: 4px;
    align-items: center;
  }
  .profile-info-preffered-theme {
    color: #000;
    font-style: normal;
    line-height: 23.632px;
    letter-spacing: 0.24px;
  }
  .profile-info-custom-input {
    border: 1px solid #efefef;
    color: #000000;
  }
  .profile-info-custom-vh {
    height: calc(100vh - 90px - 78px);
  }
  .manage-visa-card-number {
    line-height: 23.178px;
    letter-spacing: 0.235px;
  }
  :where(.css-dev-only-do-not-override-amq5gd).ant-modal .ant-modal-close {
    top: 20px;
  }
}

@media screen and (max-width: 424px) {
  .mobile-dashboard-tab-text-size {
    font-size: 12px;
  }
  .story-generate-btn {
    min-width: 100%;
  }
  .story-btn-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
  }
  .story-ideas-btn {
    width: 100%;
  }
  .upgrade-membership-plan-title {
    font-size: 23px;
    line-height: 5px;
  }
}

@media screen and (max-width: 375px) {
  .mobile-dashboard-tab-text-size {
    display: none;
  }
  .small-icon-placement1 {
    margin-top: 0px;
  }
  .small-icon-placement2 {
    margin-top: 2px;
  }
  .small-icon-placement3 {
    margin-top: -2px;
  }
  .small-icon-placement4 {
    margin-top: 5px;
  }
  .upgrade-membership-plan-title {
    font-size: 20px;
    line-height: 5px;
  }
}

@media screen and (max-width: 400px) {
  .small-custom-textarea-wrapper-height {
    height: 150px;
  }
  .small-custom-textarea-height {
    min-height: 90px;
  }
}

@media screen and (max-width: 355px) {
  .small-custom-textarea-wrapper-height {
    height: 170px;
  }
  .small-custom-textarea-height {
    min-height: 110px;
  }
}

.page.--left {
  border-right: 0;
  box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4);
  background-color: white;
}

.page.--right {
  border-left: 0;
  box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);
  background-color: white;
}

@media screen and (max-width: 768px) {
  .page-text {
    font-size: 9.5px;
    line-height: 1.1;
  }
}

@media screen and (max-width: 600px) {
  .page-text {
    font-size: 12.5px;
  }
}

@media screen and (max-width: 400px) {
  .page-text {
    font-size: 11px;
  }
}

.custom-add-new-option {
  color: #279ddb !important;
}

@media screen and (max-width: 767px) {
  .library-left-sidebar {
    position: fixed;
    left: -100%;
    transition: 10.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    z-index: 100;
  }
  .library-left-sidebar.sider-active {
    left: 10px;
    transition: 1.8s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
}
